import React, { Component } from 'react';
import EventModel from '../../../../utils/tracking/models';
import { Waypoint } from 'react-waypoint';
import { get, isEmpty } from 'lodash';
/* eslint-disable react/no-did-mount-set-state */

class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      message1: get(props, 'homepageTopPromo.text_1', ''),
      message2: get(props, 'homepageTopPromo.text_2', ''),
      link: get(props, 'homepageTopPromo.link', ''),
      name: get(props, 'homepageTopPromo.name', ''),
      fontColor: get(props, 'homepageTopPromo.font_color', ''),
      activeItem: 1
    };

    this.closeNotice = this.closeNotice.bind(this);
    this.cleanText = this.cleanText.bind(this);
    this.handlePromoBarClick = this.handlePromoBarClick.bind(this);
    this.handleWidgetImpression = this.handleWidgetImpression.bind(this);
  }

  componentDidMount() {
    // this.setState({ show: document.cookie.indexOf('fc_notice_closed') === -1 });

    setInterval(() => {
      if (this.state.show) {
        if (this.state.activeItem === 1) {
          this.setState({
            activeItem: 2
          });
        } else {
          this.setState({
            activeItem: 1
          });
        }
      }
    }, 3000);
  }

  closeNotice() {
    document.cookie = 'fc_notice_closed';
    this.setState({ show: false });
  }

  cleanText(data) {
    const regex = /(<([^>]+)>)/gi;
    const body = `${data}`;
    const result = body.replace(regex, '');
    return result;
  }

  handlePromoBarClick(data) {
    const name = this.cleanText(data);
    const promoBarClickEvent = new EventModel(
      name || 'none',
      `topPromotionBar`,
      `click`,
      `nav ${name || 'none'}`,
      `topOfPage`
    );

    promoBarClickEvent.triggerTrackingEvent(
      'default-click',
      promoBarClickEvent.getTrackingObject()
    );
  }

  handleWidgetImpression(promoName) {
    const widgetImpressionEvent = new EventModel(
      `${promoName || 'none'}`,
      `topPromotionBar`,
      `impression`,
      `nav ${promoName || 'none'}`,
      `topOfPage`
    );

    widgetImpressionEvent.triggerTrackingEvent(
      'widget-impression',
      widgetImpressionEvent.getTrackingObject()
    );
  }

  render() {
    if (!this.state.show) {
      return false;
    }
    return (
      <section className="notice">
        <Waypoint
          onEnter={() => {
            this.handleWidgetImpression(this.state.name);
          }}
        />
        <div className="inner">
          <div
            className={`slide slide1 ${
              this.state.activeItem === 1 ? 'show' : 'hide'
            }`}
            onClick={() => {
              this.handlePromoBarClick(this.state.name);
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.state.link}
              dangerouslySetInnerHTML={{ __html: this.state.message1 }}
              style={{
                color: !isEmpty(this.state.fontColor)
                  ? this.state.fontColor
                  : '#c8ff00'
              }}
            />
          </div>

          <div
            className={`slide slide2 ${
              this.state.activeItem === 2 ? 'show' : 'hide'
            }`}
            onClick={() => {
              this.handlePromoBarClick(this.state.name);
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.state.link}
              dangerouslySetInnerHTML={{ __html: this.state.message2 }}
              style={{
                color: !isEmpty(this.state.fontColor)
                  ? this.state.fontColor
                  : '#c8ff00'
              }}
            />
          </div>

          <div
            className="close"
            onClick={() => {
              this.closeNotice();
            }}
          >
            <img
              src="https://images.fastcompany.net/image/upload/v1599849526/fcweb/ex_ndgqh7.svg"
              alt="X"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Notice;
